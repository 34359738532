.logo {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_sidebar {
  grid-area: logo;
  background-color: #f8f4fc;
  box-shadow: -40px 0px 50px -60px rgba(0, 0, 0, 0.3) inset;
}

.img {
  width: 80%;
}