.grid-container {
  display: grid;
  grid-template-areas:
    "logo header"
    "sidebar main"
    "footer main";
  /* grid-gap: 10px; */
  width: 100vw;
  height: 100vh;
  grid-template-columns: 16vw 1fr;
  grid-template-rows: 8vh 1fr;
}

.main {
  grid-area: main;
  padding: 1rem 0rem;
  overflow-y: auto;
}