.media {
  text-align: center;
  width: 130px;
}

.pack_detail{
  overflow: hidden;
}

.pack_activities {
  display: flex;
  overflow: auto;
  margin-bottom: 0.75rem;
}

.pack_activities div:nth-child(odd) { margin-bottom: 0.75rem; }

.pack_activities div:nth-child(even) { margin-top: 0.75rem; }

.pack_activity{
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  border-radius: 0.75rem;
  border: 1px solid black;
}

.pack_outcomes div p{
  height: 100px;
  border-radius: 0.75rem;
  padding: 0rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pack_outcomes div:nth-child(1) p{
  background-color: #fbdad6;
}

.pack_outcomes div:nth-child(2) p{
  background-color: #fff0d9;
}

.pack_outcomes div:nth-child(3) p{
  background-color: #d9f0ff;
}

.pack_outcomes div:nth-child(4) p{
  background-color: #daecd6;
}
