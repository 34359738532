.sidebar {
  grid-area: sidebar;
  background-color: #f8f4fc;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  box-shadow: -40px 0px 50px -60px rgba(0, 0, 0, 0.3) inset;
}

.list_item {
  color: #747b8c;
  padding: 0.75rem 0rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.list_icon {
  padding-right: 0.5rem;
}

.active {
  color: #ff6004;
}

.list_item_header {
  color: #747b8c;
  opacity: 0.5;
  padding-bottom: 0.75rem;
  text-transform: uppercase;
}

.sidebar_info {
  margin-top: auto;
  align-self: center;
  width: 100%;
  text-transform: capitalize;
  padding: 0.5rem;
  margin-bottom: 1rem;
}