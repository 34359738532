.header {
  grid-area: header;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0rem 1rem;
}

.breadcrumb {
  flex-grow: 1;
}

.item_right {
  /* margin-left: auto; */
  display: flex;
  align-items: center;
}

.item_right>* {
  margin-right: 1.5rem;
}

.breadcrumb_item {
  text-decoration: none;
  text-transform: capitalize;
}