.card {
  min-height: 200px;
}

.content{
  padding-left: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.media {
  width: 160px;
}
