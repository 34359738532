.profile {
  /* min-width: 50%; */
  padding: 2rem 2rem;
  display: flex;
  /* align-items: flex-start; */
  /* justify-content: space-around; */
}

.link {
  color: black;
}

.media {
  width: 100%;
  max-width: 16rem;
}

dd {
  margin: 0;
  padding-bottom: 1rem;
}